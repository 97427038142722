export class FirebaseErrors {
	static Parse(errorCode: string): string {

		let message: string;

		switch (errorCode) {
			case 'auth/wrong-password':
				message = 'Su contraseña es incorrecta, compruébela e intente de nuevo.';
				break;
			case 'auth/network-request-failed':
				message = 'Hubo un error, por favor compruebe su conexión a internet';
				break;
			case 'auth/too-many-requests':
				message =
					'Hemos recibido demasiadas peticiones desde este dispositivo. Espere antes de hacer una nueva.';
				break;
			case 'auth/user-disabled':
				message =
					'Su cuenta ha sido desactivada o eliminada. Por favor contacte al administrador de su sistema.';
				break;
			case 'auth/requires-recent-login':
				message = 'Por favor inicie sesión nuevamente e intente de nuevo.';
				break;
			case 'auth/email-already-exists':
				message = 'El correo electronico especificado ya se encuentra en uso.';
				break;
			case 'auth/user-not-found':
				message =
					'No pudimos encontrar un usuario asocidado con este correo.';
				break;
			case 'auth/phone-number-already-exists':
				message = 'The phone number is already in use by an existing user.';
				break;
			case 'auth/invalid-phone-number':
				message = 'The phone number is not a valid phone number!';
				break;
			case 'auth/invalid-email  ':
				message = 'Ingrese un correo electrónico válido.';
				break;
			case 'auth/cannot-delete-own-user-account':
				message = 'You cannot delete your own user account.';
				break;
			default:
				message = '¡Ups! Algo salió mal. Intente de nuevo más tarde.';
				break;
		}

		return message;
	}
}
