import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginActivateGuard } from './guards/login-activate.guard';
import { AuthGuard } from './guards/auth.guard';
import { DashboardAnalyticsComponent } from './components/dashboard/dashboard-analytics/dashboard-analytics.component';
import { SectionMapComponent } from './components/section-map/section-map.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginActivateGuard]
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        canActivate: [LoginActivateGuard]
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard' },
        canActivate: [AuthGuard],
        children: [
          // { path: '', data: { title: 'Dashboard' }, component: DashboardAnalyticsComponent },
          {
            path: '',
            data: { title: 'Dashboard' },
            component: SectionMapComponent,
            pathMatch: 'full'
          },
          {
            path: 'products',
            data: { title: 'Productos' },
            loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule)
          },
          {
            path: 'orders',
            data: { title: 'Órdenes' },
            loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule)
          },
          {
            path: 'categories',
            data: { title: 'Categorías' },
            loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule)
          },
          {
            path: 'coupons',
            data: { title: 'Cupones' },
            loadChildren: () => import('./modules/coupons/coupons.module').then(m => m.CouponsModule)
          },
          {
            path:'offers',
            data: {title: 'Ofertas'},
            loadChildren: ()=> import('./modules/offers/offers.module').then(m=> m.OffersModule)
          },
          {
            path: 'configs',
            data: { title: 'Configuraciones' },
            loadChildren: () => import('./modules/configs/configs.module').then(m => m.ConfigsModule)
          },
          {
            path: 'clients',
            data: { title: 'Clientes' },
            loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule)
          },
          {
            path: 'content',
            data: { title: 'Contenido' },
            loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule)
          },
          {
            path: 'paymentMethods',
            data: { title: 'Métodos de pago' },
            loadChildren: () => import('./modules/payment-methods/payment-methods.module').then(m => m.PaymentMethodsModule)
          },
        ]
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules,
        initialNavigation: 'enabled'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
