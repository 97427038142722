<div class="card">
	<div class="card-header">
		{{ sectionName }}
	</div>
	<div class="card-body">
		<div *ngIf="children" class="row">
			<ng-container *ngFor="let route of children">
				<a style="text-decoration: none;" *ngIf="route.url" [routerLink]="route.url" class="col-md-3 p-3">
					<div class="section-child-link">
						<i [class]="route.icon + ' mb-2'"></i>
						<span> {{ route.name }} </span>
					</div>
				</a>
			</ng-container>
		</div>
	</div>
</div>