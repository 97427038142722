import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {
	breadcrumbs: Observable < Array < any >> ;
	private breadcrumbSubject: BehaviorSubject < Array < any >> ;
	constructor(private router: Router, private route: ActivatedRoute) {
		this.breadcrumbSubject = new BehaviorSubject < any[] > (new Array < any > ());
		this.breadcrumbs = this.breadcrumbSubject.asObservable();
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
			let breadcrumbs = [];
			let currentRoute = this.route.root;
			let url = '';
			let index = 0;
			do {
				const childrenRoutes = currentRoute.children;
				currentRoute = null;
				// tslint:disable-next-line:no-shadowed-variable
				childrenRoutes.forEach((route) => {
					if (route.outlet === 'primary') {
						const routeSnapshot = route.snapshot;
						// console.log(index)
						if (index === 0) {
							url = routeSnapshot.url.map(segment => segment.path).join('/');
						} else {
							url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
						}
						breadcrumbs.push({
							label: route.snapshot.data,
							url
						});
						currentRoute = route;
					}
				});
				index++;
			} while (currentRoute);
			breadcrumbs = breadcrumbs.map(
				(Route, Index) => { // Esto es para eliminar las rutas que son iguales y que solo las diferencia un '/'
					if (Index !== breadcrumbs.length - 1 && Route.url !== breadcrumbs[Index + 1].url.substring(0, breadcrumbs[Index + 1].url.length - 1)) {
						return Route;
					} else if (Index === breadcrumbs.length - 1) {
						return Route;
					}
				}).filter(Route => Route !== undefined);
			this.breadcrumbSubject.next(Object.assign([], breadcrumbs));
			// console.log(breadcrumbs)
			return breadcrumbs;
		});
	}
}