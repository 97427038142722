<div class="container-fluid animated fadeIn">
	<!-------------------------------- SPINNER LOADING -------------------------------------------->
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" [fullScreen]="false" template="<div  class='animated infinite pulse' ><img id='loading-logo' width='50px' src='../../../assets/img/white_logo.png'></div	>">
		<p style="color: white" > Cargando... </p>
	</ngx-spinner>
	<!-------------------------------- END SPINNER LOADING -------------------------------------------->

	<!---------------------------- DASHBOARD HEADER OF ANALYTICS ---------------------------->
	<div class="row mb-4">
		<div class="col-lg-4 col-12">
			<h3>Vista general de audiencia</h3>
		</div>
		<div class="col-lg-8 col-12">
			<form class="form-inline full-width" [ngClass]="isMobile ? 'justify-content-center flex-column' : 'justify-content-end'">
				<div class="form-group mr-2">
					<label for="from">Desde: </label>
				  <div class="input-group ml-1">
					<input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="fromDate" (dateSelect)="onDateInputChange($event, 'from')"
						   name="from" navigation="arrows"  ngbDatepicker #from="ngbDatepicker" [minDate]="{year: 2020, month: 4, day: 28}">
					<div class="input-group-append">
					  <button class="btn btn-outline-secondary calendar" (click)="from.toggle()" type="button"><i class="cil-calendar"></i></button>
					</div>
				  </div>
				</div>
				<div class="form-group mr-2">
					<label for="to">Hasta: </label>
				  <div class="input-group ml-1">
					<input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="toDate"  (dateSelect)="onDateInputChange($event, 'to')"
						   name="to"  navigation="arrows"  ngbDatepicker #to="ngbDatepicker">
					<div class="input-group-append">
					  <button class="btn btn-outline-secondary calendar" (click)="to.toggle()" type="button"><i class="cil-calendar"></i></button>
					</div>
				  </div>
				</div>
				<button class="btn btn-primary" (click)="filter()" [disabled]="!canFilter">Filtrar</button>
			  </form>
		</div>
	</div>
	<!---------------------------- END DASHBOARD HEADER OF ANALYTICS ---------------------------->

	<!------------------------- FIRST FOUR CHARTS ------------------------->
	<div class="row">
		<div *ngFor="let chart of Charts; let index = index" [ngClass]="{'col-lg-3 col-12': index < 4, 'col-lg-12 col-12': index == 4 || index == 7, 'col-lg-6 col-12': index > 4 && index < 7}" >
			<div class="card">
				<div class="card-header">
					{{chart.title}}
				</div>
				<div class="card-body">
					<google-chart
					[ngStyle]="{'height': index == 5 || index == 6 ? '300px' : 'inherit'}"
					style="width: 100%;"
					[title]="chart.title"
					[type]="chart.type"
					[data]="chart.data"
					[columns]="chart.columns"
					[options]="chart.options"
					(ready)="onReady()"
				>
				</google-chart>
				<div class="mt-4 justify-content-center d-flex flex-column" *ngIf="index < 4">
					<h3 class="text-center">{{totals[index]}}</h3>
					<p class="text-center" style="	color: #343a40;">{{totalsLabel[index]}}</p>
				</div>
				</div>
			</div>
		</div>
	</div>
	<!------------------------- END FIRST FOUR CHARTS ------------------------->
	<!-- GUIDE FOR ANALYTICS -->

	<!-- <h1 style="font-family: Arial, Helvetica, sans-serif;">Angular Google-Charts Demo</h1>
	<div *ngFor="let chart of charts">
	<google-chart
		width="800"
		[title]="chart.title"
		[type]="chart.type"
		[data]="chart.data"
		[columns]="chart.columns"
		[options]="chart.options"
		(ready)="onReady()"
		(select)="onSelect($event)"
		(error)="onError($event)"
		(mouseenter)="onMouseEnter($event)"
		(mouseleave)="onMouseLeave($event)"
	>
	</google-chart>
	</div>

	<google-chart
	#chart
	width="600"
	[title]="changingChart.title"
	[type]="changingChart.type"
	[data]="changingChart.data"
	[columns]="changingChart.columns"
	[options]="changingChart.options"
	>
	</google-chart>

	<button (click)="changeChart()">Change Data</button>
</div> -->