import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	public isMobile;

	constructor(private authService: AuthService, private router: Router) {
		this.isMobile = window.innerWidth < 992;
	}


	/**
	 *  When the window resizes it saves the current width in that variable
	 *
	 * @param {*} event
	 * @memberof DashboardComponent
	 * @author Kevin Sotillo
	 */
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.isMobile = window.innerWidth < 992;
	}

	ngOnInit(): void { }

	logout() {
		this.authService.logout();
		// this.
	}

}
