import { Component, OnInit } from '@angular/core';
import { navItems } from './_nav';
@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  constructor() { }

  ngOnInit(): void {
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

}
