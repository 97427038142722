export const environment = {
  production: true,
  functionsUrl: 'https://us-central1-ae-pcmania.cloudfunctions.net',
  firebaseConfig: {
    apiKey: "AIzaSyC008NMKBCE2ENZBcPkmZqzygoZvu-2Jfo",
    authDomain: "ae-pcmania.firebaseapp.com",
    projectId: "ae-pcmania",
    storageBucket: "ae-pcmania.appspot.com",
    messagingSenderId: "45578490821",
    appId: "1:45578490821:web:1236a718bb32b5f8f471ac",
    measurementId: "G-NV9FWHX34W"
  }
};
