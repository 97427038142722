import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/en';
import { registerLocaleData, CurrencyPipe, DatePipe } from '@angular/common';
registerLocaleData(localeEs, 'en');
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment.prod';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GoogleChartsModule } from 'angular-google-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ToastsContainerComponent } from './shared/toasts-container/toasts-container.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { CuiBreadcrumbComponent } from './shared/cui-breadcrumb/cui-breadcrumb.component';
import { DashboardAnalyticsComponent } from './components/dashboard/dashboard-analytics/dashboard-analytics.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProductCodeValidatorDirective } from './directives/product-code-validator.directive';
import { CouponCodeValidatorDirective } from './directives/coupon-code-validator.directive';
import { SectionMapComponent } from './components/section-map/section-map.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ToastsContainerComponent,
    DashboardComponent,
    SidebarComponent,
    CuiBreadcrumbComponent,
    DashboardAnalyticsComponent,
    ProductCodeValidatorDirective,
    CouponCodeValidatorDirective,
    SectionMapComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppHeaderModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    PerfectScrollbarModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    GoogleChartsModule.forRoot(),
    NgxDatatableModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'en' }, CurrencyPipe, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
