import { ProductsService } from './../services/products.service';
import { Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { timer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Directive({
  selector: '[appProductCodeValidator]'
})
export class ProductCodeValidatorDirective { }

export const productCodeAsyncValidator = (productsService: ProductsService, time: number = 500) => {
  return (input: FormControl) => {
    return timer(time).pipe(
      switchMap(() => productsService.checkProductCode(input.value)),
      map(res => {
        return res ? null : { codeAvailable: 'false' };
      })
    );
  };
};
