import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';
import { FirebaseErrors } from 'src/app/model/FirebaseErrors';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	@ViewChild('emailTooltip') emailTooltip;
	@ViewChild('passwordTooltip') passwordTooltip;
	// @ViewChild('resetPasswordTooltip') resetPasswordTooltip;
	loginForm: FormGroup;
	resetPasswordForm: FormGroup;
	isSubmitted = false;
	resetPasswordIsSubmitted = false;
	// counter = 0;
	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private modalService: NgbModal,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		// console.log(this.authService.isLoggedIn);
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			isRememberChecked: [false, Validators.required]
		});
		this.resetPasswordForm = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email]]
		});
	}

	get formControls() { return this.loginForm.controls; }

	login() {
		// console.log(this.loginForm.value);
		this.isSubmitted = true;
		if (this.loginForm.invalid) {
			if (this.loginForm.controls.email.errors) { this.emailTooltip.open(); }
			if (this.loginForm.controls.password.errors) { this.passwordTooltip.open(); }
			return;
		}
		const { email, password, isRememberChecked } = this.loginForm.value;
		this.authService.rememberMe = isRememberChecked;
		// console.log('Login')
		this.authService.login(email, password);
		// this.router.navigateByUrl('/admin');
	}

	openModal(content) {
		this.modalService.open(content, { centered: true });
	}

	sendPassowordResetEmail() {
		this.resetPasswordIsSubmitted = true;
		if (this.resetPasswordForm.invalid) {
			// this.resetPasswordTooltip.open();
			return;
		}

		this.authService.sendPasswordResetEmail(this.resetPasswordForm.controls['email'].value)
			.then(() => {
				this.toastService.showSuccess('Correo enviado');
			},
				err => {
					const errorMessage = FirebaseErrors.Parse(err.code); // check this helper class at the bottom
					console.error(err);
					this.toastService.showError(errorMessage);
				}
			);
	}

}
