import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { navItems } from 'src/app/shared/sidebar/_nav';
import { INavData } from '@coreui/angular';

@Component({
  selector: 'app-section-map',
  templateUrl: './section-map.component.html',
  styleUrls: ['./section-map.component.scss']
})
export class SectionMapComponent implements OnInit {

  sectionName: string;
  children: INavData[] = [];


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  private getChildren(sectionName: string): INavData[] {
    const navItem = navItems.find((item) => item.name === sectionName);

    if (navItem?.variant === "root") {
      return navItems;
    }

    return navItem?.children || [];
  }

  async ngOnInit() {
    this.sectionName = (await this.activatedRoute.data.pipe(take(1)).toPromise()).title;

    const isDashboard = this.activatedRoute.snapshot.data.title == "Dashboard";

    if (isDashboard) {
      this.children = navItems;
    } else {
      this.children = this.getChildren(this.sectionName);
    }
    

  }

}
