import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
	providedIn: 'root'
})
export class LoginActivateGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) { }
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return new Promise((resolve, reject) => {
			if (this.auth.user || this.auth.isLoggedIn) {
				this.router.navigate(['/dashboard']);
				resolve(false);
			} else {
				resolve(true);
			}
		});
	}
}
