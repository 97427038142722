import { ToastService } from 'src/app/services/toast.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from 'firebase';
import { Router } from '@angular/router';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User;
  private rememberUser = false;
  constructor(
    public afAuth: AngularFireAuth,
    public db: AngularFirestore,
    public router: Router,
    public toastService: ToastService,
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user && this.rememberUser) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else if (user) {
        this.user = user;
        sessionStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
        sessionStorage.setItem('user', null);
      }
    });
  }

  async login(email: string, password: string): Promise<any> {
    const isCMSUser = (await this.db.collection('users', ref => ref.where('email', '==', email))
      .get({ source: 'server' }).toPromise()).docs[0]?.exists;
    if (isCMSUser) {
      this.afAuth.signInWithEmailAndPassword(email, password).then(() => {
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 100);
      }).catch(err => {
        this.toastService.showError('Ha ocurrido un error');
      });
    } else {
      this.toastService.showError('Usuario no válido');
    }
  }

  async sendPasswordResetEmail(passwordResetEmail: string): Promise<any> {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async logout(): Promise<any> {
    await this.afAuth.signOut().then(() => {
      this.user = null;
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      this.router.navigate(['/login']);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
    return user !== null;
  }


  set rememberMe(rememberMe: boolean) {
    this.rememberUser = rememberMe;
  }

}
