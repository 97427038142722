import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	ChartErrorEvent,
	ChartMouseLeaveEvent,
	ChartMouseOverEvent,
	ChartSelectionChangedEvent,
	ChartType,
	Column,
	GoogleChartComponent,
} from 'angular-google-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-dashboard-analytics',
	templateUrl: './dashboard-analytics.component.html',
	styleUrls: ['./dashboard-analytics.component.scss']
})
export class DashboardAnalyticsComponent implements OnInit {

	// --------------------------------- FOR API USE -----------------------------//
	url = 'https://us-central1-iboxenergy.cloudfunctions.net/webApi/getReports';
	viewId = '216842633';

	// --------------------------- DATE FILTERING ----------------------------- //

	today = new Date();
	aWeekAgo = new Date(new Date().getTime() - 604800000);
	fromDate: NgbDateStruct = { year: this.aWeekAgo.getFullYear(), month: this.aWeekAgo.getMonth() + 1, day: this.aWeekAgo.getDate() };
	toDate: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
	canFilter = true;

	filtering = false;

	// --------------------------------- CHARTS -----------------------------//

	public Charts: {
		title: string;
		type: ChartType;
		data: any[][];
		columns?: Column[];
		options?: {};
	} [] = [];

	// --------------------------------- CHARTS DATA -----------------------------//

	private data: {
		columnHeader: {
			dimensions: string[];
			metrics: {
				values: string[];
			}[];
		},
		data: {
			maximus: any[],
			minimus: any[],
			rowCount: number,
			rows: {
				dimensions: string[],
				metrics: {
					values: string[]
				}[]
			}[],
			totals: any[]
		}
	}[];

	public totals: string[] = []; // To display totals for first four metrics
	public totalsLabel: string[] = ['Visitas únicas totales', 'Vistas totales a las páginas', 'Tiempo promedio general', 'Tasa de rebote general']; // Label for totals

	// public charts: {
	// 	title: string;
	// 	type: ChartType;
	// 	data: any[][];
	// 	columns?: Column[];
	// 	options?: {};
	// } [] = [];

	public changingChart = {
		title: 'Changing Chart',
		type: ChartType.BarChart,
		data: [
			['Copper', 8.94],
			['Silver', 10.49],
			['Gold', 19.3],
			['Platinum', 21.45]
		],
		columns: ['Element', 'Density'],
		options: {
			animation: {
				duration: 250,
				easing: 'inAndOut',
				startup: true
			}
		}
	};

	@ViewChild('chart', {
		static: true
	})
	public chart: GoogleChartComponent;


	// ----------- RESPONSIVE --------------- //
	public isMobile;

	constructor(
		private http: HttpClient,
		private spinner: NgxSpinnerService,
		) {
			this.isMobile = window.innerWidth < 992;
	}

	/**
	 *  When the window resizes it saves the current width in that variable
	 *
	 * @param {*} event
	 * @memberof DashboardAnalyticsComponent
	 * @author Kevin Sotillo
	 */
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.isMobile = window.innerWidth < 992;
	}

	public onReady() {
		// console.log('Chart ready');
		this.spinner.hide();
	}

	public onError(error: ChartErrorEvent) {
		console.error('Error: ' + error.message.toString());
	}

	public onSelect(event: ChartSelectionChangedEvent) {
		console.log('Selected: ' + event.toString());
	}

	public onMouseEnter(event: ChartMouseOverEvent) {
		console.log('Hovering ' + event.toString());
	}

	public onMouseLeave(event: ChartMouseLeaveEvent) {
		console.log('No longer hovering ' + event.toString());
	}

	public ngOnInit() {
		// console.log(this.chart);
		this.getAnalyticsInfo(this.fromDate, this.toDate);
	}

	public changeChart() {
		this.changingChart.data = [
			['Copper', Math.random() * 20.0],
			['Silver', Math.random() * 20.0],
			['Gold', Math.random() * 20.0],
			['Platinum', Math.random() * 20.0]
		];
	}


	getAnalyticsInfo(startDate: NgbDateStruct, endDate: NgbDateStruct) {
		this.spinner.show();
		const basicReport = {
			reportRequests: [{
				viewId: this.viewId,
				dateRanges: [{
					startDate: this.convertDate(startDate),
					endDate: this.convertDate(endDate)
				}],
				metrics:  [
					{expression: 'ga:users'},
					{expression: 'ga:pageViews'},
					{expression: 'ga:avgSessionDuration'},
					{expression: 'ga:bounceRate'},
					{expression: 'ga:sessions'}
				],
				dimensions: [{name: 'ga:date'}]
			},
			{
				viewId: this.viewId,
				dateRanges: [{
					startDate: this.convertDate(startDate),
					endDate: this.convertDate(endDate)
				}],
				metrics:  [
					{expression: 'ga:users'},
				],
				dimensions: [{name: 'ga:userType'}]
			},
			{
				viewId: this.viewId,
				dateRanges: [{
					startDate: this.convertDate(startDate),
					endDate: this.convertDate(endDate)
				}],
				metrics:  [
					{expression: 'ga:users'},
				],
				dimensions: [{name: 'ga:medium'}, {name: 'ga:date'}]
			},
			{
				viewId: this.viewId,
				dateRanges: [{
					startDate: this.convertDate(startDate),
					endDate: this.convertDate(endDate)
				}],
				metrics:  [
					{expression: 'ga:users'},
				],
				dimensions: [{name: 'ga:country'}]
			}
		]
		};
		const info = this.http.post(this.url, basicReport);
		info.subscribe((value: any) => {
			// console.log(value);
			this.data = value.data.reports;
			this.totals = value.data.reports[0].data.totals[0].values;
			this.totals[2] = this.fmtMSS((+this.totals[2]).toFixed(0));
			this.totals[3] = ((+this.totals[3]).toFixed(1)).toString() + ' %';
			// console.log(this.data);
			// console.log(this.totals);
			if (this.filtering) {
				this.updateCharts();
			} else {
				this.createCharts();
			}
		});
	}

	createCharts() {

		// -------------------- USERS CHART ----------------------- //

		this.Charts.push({
			title: 'Visitas Únicas',
			type: ChartType.Bar,
			columns: ['', 'Visitas únicas'],
			data: [ ...this.getDataForChartsByDate(0, 'number') ],
			options: {
				bars: 'vertical', // Required for Material Bar Charts.
				legend: {
					position: 'none'
				},
				annotation: {
					alwaysOutside: true
				},
				animation: {
					duration: 250,
					easing: 'inAndOut',
					startup: true
				}
			}
		});

		// -------------------- PAGEVIEWS CHART ----------------------- //

		this.Charts.push({
			title: 'Páginas vistas',
			type: ChartType.Bar,
			columns: ['', 'Páginas vistas'],
			data: [ ...this.getDataForChartsByDate(1, 'number') ],
			options: {
				bars: 'vertical', // Required for Material Bar Charts.
				legend: {
					position: 'none'
				},
				annotation: {
					alwaysOutside: true
				},
				animation: {
					duration: 250,
					easing: 'inAndOut',
					startup: true
				}
			}
		});

		// -------------------- AVG. DURATION SESSION CHART ----------------------- //

		this.Charts.push({
			title: 'Tiempo promedio de duración de visitas',
			type: ChartType.Bar,
			columns: ['', 'Minutos \n y segundos'],
			data: [ ...this.getDataForChartsByDate(2, 'seconds') ],
			options: {
				bars: 'vertical', // Required for Material Bar Charts.
				legend: {
					position: 'none'
				},
				annotation: {
					alwaysOutside: true
				},
				animation: {
					duration: 250,
					easing: 'inAndOut',
					startup: true
				}
			}
		});

		// -------------------- BOUNCE RATE CHART ----------------------- //

		this.Charts.push({
			title: 'Tasa de Rebote',
			type: ChartType.Bar,
			columns: ['', 'Tasa de rebote'],
			data: [ ...this.getDataForChartsByDate(3, 'percentage') ],
			options: {
				bars: 'vertical', // Required for Material Bar Charts.
				legend: {
					position: 'none'
				},
				annotation: {
					alwaysOutside: true
				},
				animation: {
					duration: 250,
					easing: 'inAndOut',
					startup: true
				},
				vAxis: {
					format: 'percent'
				},
				tooltip: {
					textStyle: {
						fontSize: 6
					}
				}
			}
		});

		// -------------------- SESSION CHART ----------------------- //

		this.Charts.push({
			title: 'Visitas',
			type: ChartType.Line,
			columns: ['', 'Visitas'],
			data: [ ...this.getDataForChartsByDate(4, 'number') ],
			options: {
				legend: {
					position: 'none'
				},
				annotation: {
					alwaysOutside: true
				},
			}
		});

		// -------------------- VISITORS TYPE CHART ----------------------- //

		this.Charts.push({
			title: 'Tipo de visitantes',
			type: ChartType.PieChart,
			columns: ['Tipo', 'Visitas'],
			data: [ ...this.getDataForChartsByUsersType() ],
			options: {
				title: null,
				legend: {
					position: 'bottom'
				},
				chartArea: {
					top: 10,
					height: 250
				}
			}
		});

		// -------------------- SOURCE TRAFFIC CHART ----------------------- //

		this.Charts.push({
			title: 'Canal de trafico',
			type: ChartType.BarChart,
			... this.getDataForChartsByTraffic(),
			// columns: ['Genre',  'Mystery/Crime', 'General',
			// 'Western', 'Literature', { role: 'annotation' } ],
			// data: [
			// 	['2010', 20, 32, 18, 5, ''],
			// 	['2020', 23, 30, 16, 9, ''],
			// 	['2030', 29, 30, 12, 13, '']
			//   ],
			options: {
				title: null,
				legend: {
					position: 'bottom',
					alignment: 'center'
				},
				isStacked: true,
				chartArea: {
					top: 10,
					height: 250
				}
			},
		});

		// -------------------- GEOMAP CHART ----------------------- //

		this.Charts.push({
			title: 'Visitantes por país',
			type: ChartType.GeoChart,
			columns: ['País', 'Visitas'],
			data: [ ...this.getDataForChartsByCountry() ],
			options: {
				title: null,
				legend: {
					position: 'bottom'
				},
				colorAxis: {colors: ['#b4cefa', '#00597c' ]},
			}
		});
	}

	updateCharts() {
		this.Charts[0].data = [ ...this.getDataForChartsByDate(0, 'number') ];
		this.Charts[1].data = [ ...this.getDataForChartsByDate(1, 'number') ];
		this.Charts[2].data = [ ...this.getDataForChartsByDate(2, 'seconds') ];
		this.Charts[3].data = [ ...this.getDataForChartsByDate(3, 'percentage') ];
		this.Charts[4].data = [ ...this.getDataForChartsByDate(4, 'number') ];
		this.Charts[5].data = [ ...this.getDataForChartsByUsersType() ];
		const { data, columns } = this.getDataForChartsByTraffic();
		this.Charts[6].data = data;
		this.Charts[6].columns = columns;
		this.Charts[7].data = [ ...this.getDataForChartsByCountry() ];
	}

	getDataForChartsByDate(indexForData: number, type: string): Array<any> {
		const data = [];
		switch (type) {
			case 'number': // For number data
				this.data[0].data.rows.forEach(datum => {
					data.push([this.transformToDate(datum.dimensions[0]), +datum.metrics[0].values[indexForData]]);
				});
				// console.log(data);
				break;
			case 'seconds': // For time data
				this.data[0].data.rows.forEach(datum => {
					const value = this.fmtMSS((+datum.metrics[0].values[indexForData]).toFixed(0));
					data.push([this.transformToDate(datum.dimensions[0]), { v: +datum.metrics[0].values[indexForData], f: value }]);
				});
				// console.log(data);
				break;
			case 'percentage': // For percentage data
				this.data[0].data.rows.forEach(datum => {
					const value = Number(datum.metrics[0].values[indexForData]).toFixed(1)
					data.push([this.transformToDate(datum.dimensions[0]), { v: value , f: `${value}%`}]);
				});
				// console.log(data);
				
				break;
		
			default:
				break;
		}
		return data;
	}

	transformToDate(date: string): string {
		return `${date.substring(6)} - ${date.substring(4, 6)} - ${date.substring(0, 4)}`;
	}

	fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

	getDataForChartsByUsersType(): Array<any> {
		const data = [];
		this.data[1].data.rows.forEach(datum => {
			data.push([datum.dimensions[0] === 'New Visitor' ? 'Nuevos visitantes' : 'Visitantes regulares', +datum.metrics[0].values[0]]);
		});
		// console.log(data);
		
		return data;
	}

	getDataForChartsByTraffic(): { columns: string[], data: any[] } {
		// We create our principal variables
		let columns = [];
		let data = [];
		// Lets get columns and rows labels
		this.data[2].data.rows.forEach(datum => {
			columns.push(datum.dimensions[0]); // Here we get all the columns
			data.push(datum.dimensions[1]); // Here we get the rows first value
		});
		columns = Array.from(new Set(columns)); // We get columns unique values
		data = Array.from(new Set(data)).map(date => [date, ...columns]); // We get rows unique values
		// console.log(columns);
		// console.log(data);
		// Lets seek for data
		this.data[2].data.rows.forEach(datum => {
			data.forEach((row: any[]) => {
				if (datum.dimensions[1] === row[0]) {
					row[row.indexOf(datum.dimensions[0])] = +datum.metrics[0].values[0];
					// row.push(datum.metrics[0].values[0]);
				}
			});
		});
		// console.log(data);
		columns = columns.map((type: string) => { // Map array to translate values
			switch (type) {
				case '(none)':
					return 'Directo';
				case 'organic':
					return 'Busqueda Organica';
				case 'referral':
					return 'Referido';
				default:
					return 'Otro';
			}
		});
		data = data.map((row: any[]) => {
			row[0] = this.transformToDate(row[0]);
			row.forEach((value: number, index: number, array) => {
				if (isNaN(value) && index !== 0) {
					array[index] = 0;
				}
			})
			return row;
		});
		// console.log('FINAL ROWS', data);
		columns = [ 'fechas', ...columns ];
		return { columns, data };
	}

	getDataForChartsByCountry() {
		const data = [];
		this.data[3].data.rows.forEach(datum => {
			data.push([datum.dimensions[0], +datum.metrics[0].values[0]]);
		});
		// console.log(data);
		return data;
	}

	convertDate(date: NgbDateStruct): string {
		if (date.month < 10 && date.day < 10) { return `${date.year}-0${date.month}-0${date.day}`; }
		else if (date.month < 10 && date.day >= 10) { return `${date.year}-0${date.month}-${date.day}`; }
		else if (date.month >= 10 && date.day < 10) { return `${date.year}-${date.month}-0${date.day}`; }
		else { return `${date.year}-${date.month}-${date.day}`; }
	}

	onDateInputChange(date: NgbDate, input: string): void {
		// console.log(event);
		if (input === 'from') {
			this.canFilter = date.before(this.toDate);
		} else {
			this.canFilter = date.after(this.fromDate);
		}
		// console.log('From ', this.fromDate);
		// console.log('to ', this.toDate);
	}

	filter() {
		this.filtering = true;
		this.ngOnInit();
	}

}
