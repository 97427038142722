import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  constructor() { }


	/**
	 *
	 *
	 * @param {(string | TemplateRef<any>)} title text to be displayed
	 * @param {(string | TemplateRef<any>)} description text to be displayed
	 * @param {*} [options={}] Adicional options:
	 * Classnames:
	 * - .bg-primary
	 * - .bg-secondary
	 * - .bg-success
	 * - .bg-danger
	 * - .bg-warning
	 * - .bg-info
	 * - .bg-light
	 * - .bg-dark
	 * - .bg-white
	 * - .bg-transparent
	 * @memberof ToastService
	 * @author Kevin Sotillo
	 */
  show(title: string | TemplateRef<any>, description?: string | TemplateRef<any>, options: any = {}) {
    const originalClassname = options.classname;
    options.classname += ' no-show';
    const index = this.toasts.push({ title, description, ...options });
    // console.log(title, description, options.classname)
    setTimeout(() => {
      this.toasts[index - 1].classname = originalClassname;
    }, 100);
  }

  showSuccess(title: string | TemplateRef<any>, description?: string | TemplateRef<any>): void {
    this.show(title, description, { classname: 'bg-success text-light', type: 'success' });
  }

  showError(title: string | TemplateRef<any>, description?: string | TemplateRef<any>): void {
    this.show(title, description, { classname: 'bg-danger text-light', type: 'error' });
  }

  showWarning(title: string | TemplateRef<any>, description?: string | TemplateRef<any>): void {
    this.show(title, description, { classname: 'bg-warning text-light', type: 'warning' });
  }

  showInfo(title: string | TemplateRef<any>, description?: string | TemplateRef<any>): void {
    this.show(title, description, { classname: 'bg-info text-light', type: 'info' });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
