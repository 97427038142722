  import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Ecommerce CMS',
    url: '/dashboard',
    icon: 'aether-ecommerce',
    variant: "root",
  },
  {
    title: true,
    name: 'Gestión de contenido',
  },
  {
    name: 'Productos',
    url: '/dashboard/products',
    icon: 'cil-inbox',
    children: [
      {
        name: 'Todos los productos',
        url: '/dashboard/products/all',
        icon: 'cil-list',
      },
      {
        name: 'Nuevo producto',
        url: '/dashboard/products/create',
        icon: 'cil-plus',
      },
      {
        name: 'Subir imagenes',
        url: '/dashboard/products/images',
        icon: 'cil-image-plus',
      },
    ],
  },
  {
    name: 'Clientes',
    url: '/dashboard/clients',
    icon: 'cil-user',
    children: [
      {
        name: 'Todos los clientes',
        url: '/dashboard/clients/all',
        icon: 'cil-list',
      },
    ],
  },
  {
    name: 'Órdenes',
    url: '/dashboard/orders',
    icon: 'cil-notes',
    children: [
      {
        name: 'Nuevas',
        url: '/dashboard/orders/new',
        icon: 'cil-av-timer',
      },
      {
        name: 'En proceso',
        url: '/dashboard/orders/in-process',
        icon: 'cil-running',
      },
      {
        name: 'Completadas',
        url: '/dashboard/orders/fulfilled',
        icon: 'cil-check-alt',
      },
      {
        name: 'Canceladas',
        url: '/dashboard/orders/cancelled',
        icon: 'cil-x',
      },
      {
        name: 'Todas las órdenes',
        url: '/dashboard/orders/all',
        icon: 'cil-list',
      },
    ],
  },
  {
    name: 'Pagos',
    url: '/dashboard/orders/payments',
    icon: 'cil-money',
    children: [
      {
        name: 'Pagos pendientes',
        url: '/dashboard/orders/payments-pending',
        icon: 'cil-av-timer',
      },
      {
        name: 'Pagos procesados',
        url: '/dashboard/orders/payments-processed',
        icon: 'cil-check-alt',
      },
      {
        name: 'Todos los pagos',
        url: '/dashboard/orders/payments',
        icon: 'cil-list',
      },
    ],
  },
  {
    name: 'Categorías',
    url: '/dashboard/categories',
    icon: 'cil-library',
    children: [
      {
        name: 'Todas las categorías',
        url: '/dashboard/categories/all',
        icon: 'cil-list',
      },
      {
        name: 'Nueva categoría',
        url: '/dashboard/categories/new',
        icon: 'cil-plus',
      },
    ],
  },
  {
    name: 'Cupones',
    url: '/dashboard/coupons',
    icon: 'cil-tag',
    children: [
      {
        name: 'Todos los cupones',
        url: '/dashboard/coupons/all',
        icon: 'cil-list',
      },
      {
        name: 'Nuevo cupón',
        url: '/dashboard/coupons/new',
        icon: 'cil-plus',
      },
    ],
  },
  {
    name: 'Ofertas',
    url: '/dashboard/offers',
    icon: 'cil-tag',
    children: [
      {
        name: 'Todas las Ofertas',
        url: '/dashboard/offers/all',
        icon: 'cil-list',
      },
      {
        name: 'Nueva Oferta',
        url: '/dashboard/offers/new',
        icon: 'cil-plus',
      },
    ],
  },
  {
    name: 'Configuraciones',
    url: '/dashboard/configs',
    icon: 'cil-settings',
    children: [
      {
        name: 'Países/impuestos',
        url: '/dashboard/configs/countries-taxes',
        icon: 'cil-asterisk',
      },
      // {
      //   name: 'Precio de delivery',
      //   url: '/dashboard/configs/delivery-price',
      //   icon: 'cil-car-alt',
      // },
      {
        name: 'Tasas de cambio',
        url: '/dashboard/configs/currency-conversion',
        icon: 'cil-dollar',
      },
      {
        name: 'Métodos de envío',
        url: '/dashboard/configs/shipping-methods',
        icon: 'cil-send',
      },
      {
        name: "Compañías de envío",
        url: '/dashboard/configs/shipping-companies',
        icon: 'cil-envelope-letter',
      },
      {
        name: "Impuestos",
        url: '/dashboard/configs/taxes',
        icon: 'cil-calculator',
      }
    ],
  },
  {
    name: 'Contenido',
    url: '/dashboard/content',
    icon: 'cil-newspaper',
    children: [
      {
        name: 'Banner del Home',
        url: '/dashboard/content/home-banner',
        icon: 'cil-square',
      },
      {
        name: 'Home general',
        url: '/dashboard/content/home',
        icon: 'cil-home',
      },
      {
        name: 'Política de pagos y reembolsos',
        url: '/dashboard/content/policies',
        icon: 'cil-short-text',
      },
      {
        name: 'Footer',
        url: '/dashboard/content/footer',
        icon: 'cil-space-bar',
      },
      {
        name: 'Politica de entregas',
        url: '/dashboard/content/delivery-policy',
        icon: 'cil-description',
      },
      {
        name: 'Órdenes',
        url: '/dashboard/content/orders-general-info',
        icon: 'cil-notes',
      },
      {
        name: 'Términos y Condiciones',
        url: '/dashboard/content/terms-and-conditions',
        icon: 'cil-book',
      },
      {
        name: 'Política de privacidad',
        url: '/dashboard/content/privacy-policy',
        icon: 'cil-lock-locked',
      },
      {
        name: 'Reembolsos',
        url: '/dashboard/content/refunds',
        icon: 'cil-cash',
      },
      {
        name: 'Envios',
        url: '/dashboard/content/shipping',
        icon: 'cil-truck',
      },
      {
        name: 'Nosotros',
        url: '/dashboard/content/us',
        icon: 'cil-info',
      },
      {
        name: 'Contáctanos',
        url: '/dashboard/content/contact-us',
        icon: 'cil-phone',
      },
      
    ],
  },
  {
    name: 'Métodos de pago',
    url: '/dashboard/paymentMethods',
    icon: 'cil-bank',
    children: [
      {
        name: 'Todos los métodos de pago',
        url: '/dashboard/paymentMethods/all',
        icon: 'cil-list',
      },
    ],
  },
  // {
  //   name: 'Ir al comercio',
  //   url: 'https://ecommerce.aethersol.dev',
  //   icon: 'aether-ecommerce',
  //   variant: 'custom',
  //   class: 'mt-auto',
  //   attributes: {
  //     target: '_blank',
  //     rel: 'noopener',
  //   },
  // },
];
