import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { AuthService } from '../services/auth.service';
// import { AngularFirestore } from 'angularfire2/firestore';
// import { User } from '../models/user';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		// console.log(this.auth.user);
		return new Observable(observer => {
			if (this.auth.user || this.auth.isLoggedIn) {
				return observer.next(true);
			} else {
				this.router.navigate(['/login']);
				return observer.next(false);
			}
		});
	}
}
