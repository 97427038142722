<app-header *ngIf="isMobile" [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
  [navbarBrandFull]="{src: '../../../assets/img/logoAetherEcommerce.svg', width: 89, height: 25, alt: 'Ibox Energy'}"
  [navbarBrandMinimized]="{src: 'src/assets/img/logo-aetherecommerce.svg', width: 30, height: 30, alt: 'Ibox Energy'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]='false' [mobileAsideMenuToggler]="false">
</app-header>
<div class="app-body">
  <sidebar></sidebar>
  <main class="main" [perfectScrollbar]>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" [fullScreen]="false"
      template="<div  class='animated infinite pulse' ><img id='loading-logo' width='50px' src='../../../assets/img/white_logo.png'></div	>">
      <p style="color: white"> Cargando... </p>
    </ngx-spinner>
    <app-cui-breadcrumb>
      <!-- Breadcrumb Menu -->
      <li class="breadcrumb-menu">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" (click)="logout()"><i class="cil-account-logout"></i> &nbsp;Logout</a>
        </div>
      </li>
    </app-cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-footer class="app-footer">
  <span><a href="https://ecommerce.aethersol.dev">Aether Ecommerce CMS</a> &copy; 2020</span>
</app-footer>
