// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  functionsUrl: 'https://us-central1-ae-pcmania.cloudfunctions.net',
  firebaseConfig: {
    apiKey: "AIzaSyC008NMKBCE2ENZBcPkmZqzygoZvu-2Jfo",
    authDomain: "ae-pcmania.firebaseapp.com",
    projectId: "ae-pcmania",
    storageBucket: "ae-pcmania.appspot.com",
    messagingSenderId: "45578490821",
    appId: "1:45578490821:web:1236a718bb32b5f8f471ac",
    measurementId: "G-NV9FWHX34W"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
