<ngb-toast
*ngFor="let toast of toastService.toasts"
[class]="toast.classname"
[autohide]="true"
[delay]="toast.delay || 5000"
(hide)="beforeHide(toast)"
>
<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
  <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
</ng-template>

<ng-template #text>
    <!-- {{ toast.textOrTpl }} -->
    <div class="toast-container d-flex">
        <div class="row">
            <div class="col-2 d-flex justify-content-center align-items-center" [ngSwitch]="toast.type">
                    <i *ngSwitchCase="'success'" class="cil-check"></i>
                    <i *ngSwitchCase="'error'" class="cil-x-circle"></i>
                    <i *ngSwitchCase="'warning'" class="cil-warning"></i>
                    <i *ngSwitchCase="'info'" class="cil-info"></i>
                    <!-- <i *ngSwitchDefault class="cil-check"></i> -->
            </div>
            <div class="col-10 d-flex flex-column">
                <div class="toast-title">{{toast.title}}</div>
                <div class="toast-message" *ngIf="toast.description">{{toast.description}}</div>
            </div>
        </div>
    </div>
</ng-template>



<!-- <ng-template [ngSwitch]='toast.type'>
    <ng-template *ngSwitchCase="'success'" >
        <div class="toast-container d-flex">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-10 d-flex flex-column">
                    <div class="toast-title">{{toast.title}}</div>
                    <div class="toast-message" *ngIf="toast.description">{{toast.description}}</div>
                </div>
            </div>
        </div>
        
        
    </ng-template>
    <ng-template *ngSwitchCase="'errror'">

    </ng-template>
    <ng-template *ngSwitchCase="toast.warning">

    </ng-template>
    <ng-template *ngSwitchCase="toast.info">

    </ng-template>
    <ng-template *ngSwitchDefault>

    </ng-template>
    
</ng-template> -->


</ngb-toast>