import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
	selector: 'app-toasts-container',
	templateUrl: './toasts-container.component.html',
	styleUrls: ['./toasts-container.component.scss']
})
export class ToastsContainerComponent implements OnInit {

	constructor(public toastService: ToastService) { }

	ngOnInit(): void {
	}

	isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

	beforeHide(toast): void {
		toast.classname += ' no-show';
		setTimeout(() => {
			this.toastService.remove(toast)
		}, 500);
	}
}
