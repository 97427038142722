import { CouponsService } from './../services/coupons.service';
import { Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { timer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Directive({
  selector: '[appCouponCodeValidator]'
})
export class CouponCodeValidatorDirective { }

export const couponCodeAsyncValidator = (couponService: CouponsService, time: number = 500) => {
  return (input: FormControl) => {
    return timer(time).pipe(
      switchMap(() => couponService.checkCouponCode(input.value)),
      map(res => {
        return res ? { codeAvailable: 'true' } : { codeAvailable: 'false' };
      })
    );
  };
};
