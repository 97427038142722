import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Observable } from 'rxjs';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'app-cui-breadcrumb',
	templateUrl: './cui-breadcrumb.component.html'
})
export class CuiBreadcrumbComponent implements OnInit, OnDestroy {
	@Input() fixed: boolean;

	public breadcrumbs: Observable<any>;
	private readonly fixedClass = 'breadcrumb-fixed';

	constructor(
		@Inject(DOCUMENT) private document: any,
		private renderer: Renderer2,
		public service: BreadcrumbService,
	) { }

	public ngOnInit(): void {
		this.isFixed(this.fixed);
		this.breadcrumbs = this.service.breadcrumbs;
	}

	ngOnDestroy(): void {
		this.renderer.removeClass(this.document.body, this.fixedClass);
	}

	isFixed(fixed: boolean = this.fixed): void {
		if (fixed) {
			this.renderer.addClass(this.document.body, this.fixedClass);
		}
	}
}
